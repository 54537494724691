import { IDfaBack, IDfaFront } from '../dfa'
import { SignTransactionFullType, SignTransactionShortType } from '../sign'

export enum EEarlyRepaymentStatus {
    PLACED = 'placed',
    APPROVED = 'approved',
    STOPPED = 'stopped',
    EXECUTED = 'executed',
}

export const EarlyRepaymentStatusTranslate = {
    [EEarlyRepaymentStatus.APPROVED]: 'В процессе',
    [EEarlyRepaymentStatus.EXECUTED]: 'Исполнена',
    [EEarlyRepaymentStatus.PLACED]: '  Новая',
    [EEarlyRepaymentStatus.STOPPED]: 'Истек срок',
}

export const EarlyRepaymentStatusColors: Record<
    EEarlyRepaymentStatus,
    { color: string; title: string; name: string }
> = {
    [EEarlyRepaymentStatus.APPROVED]: {
        color: 'default',
        title: EarlyRepaymentStatusTranslate[EEarlyRepaymentStatus.APPROVED],
        name: EEarlyRepaymentStatus.APPROVED,
    },
    [EEarlyRepaymentStatus.EXECUTED]: {
        color: 'default',
        title: EarlyRepaymentStatusTranslate[EEarlyRepaymentStatus.EXECUTED],
        name: EEarlyRepaymentStatus.EXECUTED,
    },
    [EEarlyRepaymentStatus.PLACED]: {
        color: 'success',
        title: EarlyRepaymentStatusTranslate[EEarlyRepaymentStatus.PLACED],
        name: EEarlyRepaymentStatus.PLACED,
    },
    [EEarlyRepaymentStatus.STOPPED]: {
        color: 'error',
        title: EarlyRepaymentStatusTranslate[EEarlyRepaymentStatus.STOPPED],
        name: EEarlyRepaymentStatus.STOPPED,
    },
}

export interface IEarlyRepaymentItem {
    uuid: string
    asset_address: string
    asset_id: string
    wallet_address: string
    wallet_id: number
    user_uuid: string
    user_full_name: string
    status: EEarlyRepaymentStatus
    amount: number
    price_per_dfa: number
    fee_rate_uuid: string
    fee_lock_id: string
    fee_amount: number
    valid_until: string
    money_lock_id: string
    payment_order_fee_rate_uuid: string
    force_repayment_base: string
    force_repayment_document_uuid: string
    trx_hash: string
    signee_skid: string
    block_timestamp: string
    executed_at: string
    canceled_at: string
    created_at: string
    updated_at: string
    deleted_at: string
    redeem_total_price: number
    asset_ticker_symbol?: string
    emitter_full_name?: string
    emitter_id?: string
    meta: { is_last: boolean, round_error: number }
}

export interface IEarlyRepaymentPostReques {
    // идентификатор выпуска ЦФА
    asset_id: number
    // количество досрочно погашаемых ЦФА
    amount: number
    // идентификатор владельца
    user_uuid: string
    // subject key ID сертификата
    skid: string
}

export interface IEarlyRepaymentRquest {
    request_uuid: string
    skid: string
    fee?: number
}

export interface IEarlyRepaymentPostResponse {
    uuid: string
    type: string
    address: string
    hash: string
    skid: string
    sign: string
    signee: 'system' | 'user'
    public_key: string
    params: unknown // ??? нет типизации
    status: string
    payload: string
    error: string
    created_at: string
}

export interface IEarlyRepaymentBuIssuerResponse {
    request_uuid: string
    repayment_fee: number
    transaction: SignTransactionFullType
}

export interface IEarlyRepaymentFeeResponse {
    request_uuid: string
}

export interface IEarlyRepaymentFeeRequest {
    fee: number // копейки
}

export interface IWalletBalance {
    id: number
    asset_id: number
    amount_dfa: number
    asset_title: string
    asset_ticker_symbol: string
    emitter_id: string
    emitter_full_name: string
    repurchase_percent: number
    active_amount_dfa: number
    blocked_amount_dfa: number
    total_amount_dfa: number
    repayment_amount_dfa: number
    injunction_amount_dfa: number
    early_repayment_blocked_amount_dfa: number
    early_repayment_amount_dfa: number
    is_investor_repayment_confirmed: true
}

export interface IEarlyRepayment {
    asset: IDfaBack
    request: Omit<IEarlyRepaymentItem, 'meta'> & { meta: string }
    wallet_balance: IWalletBalance
    repayment_fee_amount: number
}

export interface IEarlyRepaymentFrontAsset {
    asset: IDfaFront
    request: Partial<IEarlyRepaymentItem>
    wallet_balance: Partial<IWalletBalance>
    repayment_fee_amount?: number
}

export interface IEarlyRepaymentForcePostResponse {
    request_uuid: string
    skid: string
    force_repayment_base: string
    force_repayment_document_uuid: string
}

export interface IEarlyRepaymentForcePostRequest {
    item: {
        uuid: string
        type: string
        address: string
        hash: string
        skid: string
        sign: string
        signee: 'system' | 'user'
        public_key: string
        params: unknown
        status: string
        payload: string
        error: string
        created_at: string
    }
}

export interface IEarlyRepaymentForcePutRequest {
    request_uuid: string
    force_repayment_base: string
    force_repayment_document_uuid: string
    repayment_fee: number
    is_making_fee: boolean
    transaction: SignTransactionShortType
}
