export enum EmployeeStatus {
    active = 'ACTIVE',
    archived = 'ARCHIVED',
    blocked = 'BLOCKED',
}

export const employeeStatusTranslate = {
    [EmployeeStatus.active]: 'Активен',
    [EmployeeStatus.archived]: 'В архиве',
    [EmployeeStatus.blocked]: 'Заблокирован',
}
