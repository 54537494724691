import { BankAccountDetailsFullModel, NominalAccountModel } from '../nominalAccount'
import { PaymentOrderExtensions, PaymentOrderStatuses } from '../paymentOrder'
import { FilterField, TablePaginationParam } from '../request'
import { TransactionStatuses, TransactionTypes } from '../transaction'

type BalanceType = {
    available: number
    reserved: number
    total: number
}
type MoneyAccountShort = {
    balance: BalanceType | number
    currency: number
    id: string
    is_blocked: boolean
    number: number
    user_uuid: string
    nominal_account?: NominalAccountModel
}
export type MoneyAccountType = MoneyAccountShort & {
    created_at: number
    type?: ESpecialAccountType
}
export type MoneyAccountFull = MoneyAccountShort & {
    reserves: number
    total: number
    user_name: string
}

export type MoneyAccountByNominal = {
    balance: number
    created_at: number
    currency: number
    full_user_name: string
    id: string
    is_blocked: boolean
    number: number
    user_uuid: string
}

export type GetFilePaymentOrderPayload = {
    uuid: string
    extension: PaymentOrderExtensions
}

export type GetFilePaymentOrderResponse = {
    name: string
    data: string
}

export interface GetPaymentOrderParams extends TablePaginationParam {
    status?: PaymentOrderStatuses
    created_at?: FilterField<number>[]
    transaction_uuid?: string
}

export type AvailableOrderTransaction = 'id' | 'created_at'

export interface GetTransactionParams extends TablePaginationParam<AvailableOrderTransaction> {
    status?: TransactionStatuses[]
    type?: TransactionTypes[]
    created_at?: FilterField<number>[]
    account_id?: string[]
    recipient_id?: string[]
    sender_id?: string[]
    amount?: unknown
}

export interface IMoneyAccountsSummary {
    accounts_count: number
    total_balance: number
    total_reserves: number
    total: number
}

export interface IBankAccountsUser {
    id: string
    user_uuid: string
    is_active: boolean
    is_default: boolean
    created_at: number
    account_details: BankAccountDetailsFullModel
}

export enum ESpecialAccountType {
    OIS = 'ois',
    TECHSAU = 'unknown',
    TAX = 'tax'
}
