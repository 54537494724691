import dayjs from 'dayjs'

export const enum DocumentsEnum {
    register_of_qualified_investors = 'register_of_qualified_investors',
    user_account_operations_history = 'user_account_operations_history',
    beneficiary_nominal_account_registry = 'beneficiary_nominal_account_registry',
    nominal_account_operations_history = 'nominal_account_operations_history',
    nominal_account_operations_history_by_accounts = 'nominal_account_operations_history_by_accounts',
    user_profile = 'user_profile',
    dfa_release_result = 'dfa_release_result',
    user_dfa_operations_history = 'user_dfa_operations_history',
    user_dfa_wallet = 'user_dfa_wallet',
    closing_documents = 'closing_documents',
    dfa_investors = 'dfa_investors',
    cbr = 'cbr',
}

export enum DocumentReportTypeEnum {
    ois_account = 'ois_account',
    all_accounts = 'all_accounts',
    specific_account = 'specific_account',
}

export const documentReportType = new Map([
    [DocumentsEnum.nominal_account_operations_history, DocumentReportTypeEnum.ois_account],
    [
        DocumentsEnum.nominal_account_operations_history_by_accounts,
        DocumentReportTypeEnum.all_accounts,
    ],
])

export type GetDocumentsParams = {
    user_id?: string
    account_id?: string
    dfa_id?: number
    ticker?: string
    asset_id?: number
}

type GetDocumentTitle = {
    userId?: string
    userName?: string
    ticker?: string
    fileName?: string
}

export const documentsEnumInfo = {
    [DocumentsEnum.register_of_qualified_investors]: {
        getTitle: ({ userId }: GetDocumentTitle) =>
            userId ? 'Выписка из реестра' : 'Реестр квалифицированных инвесторов',
        extension: '.pdf',
        contentType: 'application/pdf',
    },
    [DocumentsEnum.user_account_operations_history]: {
        getTitle: () => 'Выписка по лицевому счету',
        extension: '.pdf',
        contentType: 'application/pdf',
    },
    [DocumentsEnum.beneficiary_nominal_account_registry]: {
        getTitle: () => 'Выписка в разрезе бенефициаров',
        extension: '.doc',
        contentType: 'application/msword',
    },
    [DocumentsEnum.nominal_account_operations_history]: {
        getTitle: () => 'Выписка по номинальному счету',
        extension: '.xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    [DocumentsEnum.nominal_account_operations_history_by_accounts]: {
        getTitle: () => 'Выписка по номинальному счету в разрезе операций по лицевым счетам',
        extension: '.xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    [DocumentsEnum.user_profile]: {
        getTitle: () => 'Анкета клиента',
        extension: '.xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    [DocumentsEnum.dfa_release_result]: {
        getTitle: ({ ticker }: GetDocumentTitle) =>
            `Отчёт об итогах выпуска ЦФА_${dayjs().format('DD.MM.YYYY')}_${ticker}`,
        extension: '.docx',
        contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    [DocumentsEnum.user_dfa_operations_history]: {
        getTitle: ({ userName }: GetDocumentTitle) =>
            `Отчет об операциях ${userName}_${dayjs().format('DD.MM.YYYY')}`,
        extension: '.xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    [DocumentsEnum.user_dfa_wallet]: {
        getTitle: ({ userName }: GetDocumentTitle) =>
            `Отчёт о ЦФА ${userName}_${dayjs().format('DD.MM.YYYY')}`,
        extension: '.xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    [DocumentsEnum.closing_documents]: {
        getTitle: ({ fileName }: GetDocumentTitle) => fileName,
        extension: '.zip',
        contentType: 'application/zip',
    },
    [DocumentsEnum.dfa_investors]: {
        getTitle: () => `Банковские реквизиты Владельцев`,
        extension: '.xlsx',
        contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    [DocumentsEnum.cbr]: {
        getTitle: () => `Отчётность для Банка России по форме 0420723`,
        extension: '.zip',
        contentType: 'application/zip',
    },
}